import Head from '@jetshop/core/components/Head';
// import { Shortcodes } from '@jetshop/flight-shortcodes';
import StartPageRenderer from '@jetshop/ui/StartPageRenderer';
import { styled } from 'linaria/react';
import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
// import Newsletter from '../Cart/Newsletter/NewsletterField.js';
import MaxWidth from '../Layout/MaxWidth';
import LoadingPage from '../LoadingPage';
import { theme } from '../Theme';
// import ContentShortcodes from '../Theme/Shortcodes/ContentShortcodes';
import blocksStartpage from '../Theme/ContentEditor/blocksStartpage';
// import { stripTags } from '../Theme/Utils/common';
import startPageQuery from './StartPageQuery.gql';
import useSetBodyClass from '../Theme/useSetBodyClass';
import getPropsOrFieldValues from '../Theme/Utils/getPropsOrFieldValues';
import HelloRetailFetcher, {
  getBoxLabelByNiceName
} from '../Theme/HelloRetail/HelloRetail';
import HelloRetailLayout from '../Theme/ContentEditor/Root/HelloRetailLayout.js';

const StartPageWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${theme.below.sm} {
    padding-left: 0px;
    padding-right: 0px;
  }

  /* ${theme.above.lg} {
    padding: 0 4rem;
  } */
`;

export const MainSectionWrapper = styled(MaxWidth)`
  width: 100%;
  align-self: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  ${theme.below.sm} {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const MaybeHelloRetailFetcher = ({ children, helloRetailItems }) => {
  const startpageBoxes = helloRetailItems.map(item => {
    const helloRetailLabelPropery = item?.properties?.find(
      prop => prop.name === 'helloRetailLabel'
    );

    const boxLabelNiceName = getPropsOrFieldValues(helloRetailLabelPropery)
      ?.value;

    return getBoxLabelByNiceName(boxLabelNiceName);
  });

  // return <div>{children}</div>;
  return (
    <HelloRetailFetcher boxKeyLabels={startpageBoxes}>
      {boxes => <>{children(boxes)}</>}
    </HelloRetailFetcher>
  );
};

const StartPage = ({ startPageId }) => {
  useSetBodyClass('start');

  return (
    <Fragment>
      <StartPageWrapper>
        <Query
          variables={startPageId && { startPageId: startPageId }}
          query={startPageQuery}
        >
          {result => {
            const { loading, data } = result;
            if (loading) return <LoadingPage />;
            if (!data) return null;

            const helloRetailContentEditorBlockKey = 'Hello Retail Section';

            const maybeHelloRetailItems = result?.data?.startPage?.data?.items?.filter(
              item => item?.type === helloRetailContentEditorBlockKey
            );

            return data && data.startPage && data.startPage.content ? (
              <>
                <MaybeHelloRetailFetcher
                  helloRetailItems={maybeHelloRetailItems}
                >
                  {boxes => {
                    return (
                      <StartPageRenderer
                        result={result}
                        startPageComponents={{
                          ...blocksStartpage,
                          ...(boxes
                            ? {
                                [helloRetailContentEditorBlockKey]: props => (
                                  <HelloRetailLayout
                                    helloRetailBoxes={boxes}
                                    {...props}
                                  />
                                )
                              }
                            : {})
                        }}
                      />
                    );
                  }}
                </MaybeHelloRetailFetcher>

                <MaxWidth>
                  {/* <Shortcodes
                    content={stripTags(data.startPage.content)}
                    components={{
                      ...ContentShortcodes
                    }}
                  /> */}
                  <Head data={data.startPage.head} />
                </MaxWidth>
              </>
            ) : null;
          }}
        </Query>
        {/* <Newsletter /> */}
      </StartPageWrapper>
    </Fragment>
  );
};

export default StartPage;
