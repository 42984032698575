import React from 'react';
import { css } from 'linaria';
import getPropsOrFieldValues from '../../Utils/getPropsOrFieldValues';
import Layout from '../Components/Layout';
import { ProductGrid } from '../../../CategoryPage/ProductGrid';
import { theme } from '../../../Theme';
import { getBoxLabelByNiceName } from '../../HelloRetail/HelloRetail';

const helloRetailCSS = css`
  padding: 0 1rem;
  flex-flow: row wrap;
  .layout-child {
    width: 100%;
  }
  h2 {
    text-align: center;
    margin-bottom: ${theme.space[2]};
  }

  ${theme.above.md} {
    padding: 0 2.5rem;
  }
`;

const MaybeLayout = ({ children, layoutProps }) => {
  if (layoutProps) {
    const { container, margin, gap } = getPropsOrFieldValues(layoutProps);

    return (
      <Layout
        className={helloRetailCSS}
        container={container}
        margin={margin}
        gap={gap}
      >
        {children}
      </Layout>
    );
  } else {
    return <>{children}</>;
  }
};

const spliceComponentFromChildren = (children, componentKey) => {
  const componentIndex = children.findIndex(child =>
    child?.key.includes(componentKey)
  );

  children.splice(componentIndex, 1);

  return children;
};

const HelloRetailLayout = props => {
  const children = props?.children;

  if (!children?.length > 0) return null;

  const { helloRetailLabel } = getPropsOrFieldValues(props);

  const layoutProps = {
    ...children.find(child => child?.key.indexOf('component-layout') !== -1)
      ?.props
  };

  const boxKeyLabel = getBoxLabelByNiceName(helloRetailLabel);

  const { helloRetailBoxes } = props;

  const products = helloRetailBoxes?.[boxKeyLabel];

  const isCarousel = products?.length > 4;

  if (!products) return null;

  // Disable gap for HelloRetailSection
  layoutProps.gap = null;

  const HtmlComponent = children.find(
    child => child?.key.indexOf('component-html') !== -1
  );

  spliceComponentFromChildren(children, 'component-layout');
  spliceComponentFromChildren(children, 'component-html');

  return (
    <MaybeLayout layoutProps={layoutProps}>
      {HtmlComponent ? <h2>{HtmlComponent}</h2> : null}
      <ProductGrid
        products={products}
        carousel={isCarousel}
        as={isCarousel ? 'div' : 'ul'}
      />
    </MaybeLayout>
  );
};

export default HelloRetailLayout;
